<template>
  <form @submit.prevent="submit">
    <div class="p-mb-3 p-input-filled">
      <div class="p-field-checkbox">
        <Checkbox id="binary" v-model="formData.partial" :binary="true" />
        <label for="binary">Partial?</label>
      </div>
      <div class="p-field">
        <label for="name"><b>Name</b></label>
        <InputText
          id="name"
          placeholder="Name"
          type="text"
          class="email-name"
          v-model="formData.name"
          @blur="validation.formData.name.$touch()"
          :class="{ 'p-invalid': validation.formData.name.$error }"
        />
        <BaseValidationErrors
          :field="validation.formData.name"
          :rules="['required']"
        />
      </div>
      <div class="p-field" v-if="!formData.partial">
        <label for="subject"><b>Subject</b></label>
        <InputText
          id="subject"
          placeholder="Subject"
          type="text"
          class="email-subject"
          v-model="formData.subject"
        />
      </div>
      <div class="p-field">
        <label for="html"><b>Html Body</b></label>
        <Textarea
          id="html"
          :autoResize="true"
          placeholder="Html"
          class="email-html"
          v-model="formData.html"
          @blur="validation.formData.html.$touch()"
          :class="{ 'p-invalid': validation.formData.html.$error }"
        />
        <BaseValidationErrors
          :field="validation.formData.html"
          :rules="['required']"
        />
      </div>
      <div class="p-field">
        <label for="html"><b>Text Body</b></label>
        <Textarea
          id="text"
          :autoResize="true"
          placeholder="Text"
          class="email-text"
          v-model="formData.text"
          @blur="validation.formData.text.$touch()"
          :class="{ 'p-invalid': validation.formData.text.$error }"
        />
        <BaseValidationErrors
          :field="validation.formData.text"
          :rules="['required']"
        />
      </div>
    </div>
    <Button
      type="submit"
      class="submit"
      :disabled="disabled || validation.$invalid"
      :label="label"
    />
  </form>
</template>

<script>
import { ref, watchEffect } from 'vue'
import Button from 'primevue/button'
import InputText from 'primevue/inputtext'
import Textarea from 'primevue/textarea'
import Checkbox from 'primevue/checkbox'
import useVuelidate from '@vuelidate/core'
import { required } from '@vuelidate/validators'
import BaseValidationErrors from '@/components/BaseValidationErrors.vue'

export default {
  components: { Button, InputText, Textarea, Checkbox, BaseValidationErrors },
  props: {
    disabled: {
      type: Boolean,
      default: false
    },
    label: {
      type: String,
      default: 'Save Email Template'
    },
    email: {
      type: Object,
      default: new Object()
    }
  },
  emits: ['submitted'],
  setup(props, { emit }) {
    const formData = ref({
      name: null,
      subject: null,
      text: null,
      html: null,
      active: true,
      partial: false
    })

    const rules = {
      formData: {
        name: { required },
        text: { required },
        html: { required }
      }
    }

    const validation = useVuelidate(rules, { formData })

    watchEffect(() => {
      if (Object.keys(props.email).length) {
        formData.value = props.email
      }
    })

    function submit() {
      validation.value.$touch()
      if (!validation.value.$invalid) emit('submitted', formData.value)
    }

    return { formData, validation, submit }
  }
}
</script>

<style lang="scss" scoped>
@import '@/scss/forms.scss';
</style>
